import React, { Component } from 'react';
import { SelectGroupStyle, TableStyle } from '../PlayerList/PlayerListStyle';
import { ContainerStyle, ClubBadgeBg, FiltersArea, ClubName } from './FootballCalendarStyle';
import Select from '../UI/Select/Select';
import Link from '../UI/Link/Link';
import { Match } from '../../types';
import { Redirect } from "react-router-dom";
import moment from 'moment';
import { groupBy, uniqBy } from 'lodash';
import Button from '../UI/Button/Button';
import Icon from 'antd/lib/icon';
import { Title, TitleType } from '..';

export interface CalendarProps {
	data: any[];
	size: number;
	isLoading?: boolean;
	showMonthsFilter?: boolean;
	assetsCdn?: string;
	weekId: number;
	showHeader?: boolean;
	t: any;
}

export interface CalendarState {
	navigateToMatchId: any,
	weekId: number,
	filters: any,
	filtersActivated: boolean
}

class Calendar extends Component<CalendarProps, CalendarState> {

	constructor(props: CalendarProps) {
		super(props);

		this.state = {
			filters: {
				month: -1
			},
			filtersActivated: false,
			navigateToMatchId: null,
			weekId: props.weekId
		};
	}

	componentDidUpdate(prevProps: CalendarProps) {
		if (this.props.weekId !== prevProps.weekId) {
			this.setState({ weekId: this.props.weekId });
		}
	}


	onFilterChange = (name: string, value: string | number) => {
		const filters: any = Object.assign({}, this.state.filters, { [name]: value });
		this.setState({ filters })
	};

	matchFilter = (match: Match, filters: any) => {
		let show = true;
		if (filters.month !== -1 && filters.month !== moment(match.date).month()) {
			show = false;
		}

		return show;
	};

	enableFilters = (e: any) => {
		this.setState({ filtersActivated: true });
	};

	onCalendarWeekChanged = (weekId: number) => {
		this.setState({ weekId });
	};

	render() {
		moment.locale('nl');

		console.log(this.props);
		console.log(this.state);

		const { data, size, showHeader, isLoading, showMonthsFilter, assetsCdn, t } = this.props;
		const { navigateToMatchId, weekId, filtersActivated } = this.state;
		const visibleWeekMatches = data && data.filter((match: Match) => match.weekId === weekId) || [];

		const matchesList = visibleWeekMatches
			.filter(match => this.matchFilter(match, this.state.filters));

		const groupByDays = groupBy(matchesList, match => moment(match.date).format('YYYY-MM-DD'));
		const matchWeekIds = uniqBy(data, match => match.weekId)
			.map(match => ({ id: match.weekId, name: `${t('general.footballWeek')} ${match.weekId}` }))
			.sort((firstWeek: any, secondWeek: any) => firstWeek.id - secondWeek.id);

		const selectedWeekId = matchWeekIds.find((item: any) => item.id === weekId);

		const months = [
			{ id: -1, name: 'Alle maanden' },
			{ id: 0, name: 'Januari' },
			{ id: 1, name: 'Februari' },
			{ id: 2, name: 'Maart' },
			{ id: 3, name: 'April' },
			{ id: 4, name: 'Mei' },
			{ id: 5, name: 'Juni' },
			{ id: 6, name: 'Juli' },
			{ id: 7, name: 'Augustus' },
			{ id: 8, name: 'September' },
			{ id: 9, name: 'Oktober' },
			{ id: 10, name: 'November' },
			{ id: 11, name: 'December' },
		];

		const columns = [
			{
				title: 'Home',
				key: 'homeId',
				dataIndex: 'homeId',
				width: '40%',
				render: (homeId: any, record: any) => {
					const clubBadge = `${assetsCdn}/badges/${homeId.id}.png`;

					return <React.Fragment>
						<ClubName className="team-name" fullName={record.homeId.name} shortName={record.homeId.short}></ClubName>
						<ClubBadgeBg src={clubBadge} />
					</React.Fragment>;
				}
			},
			{
				title: 'Rit',
				key: 'feedUrl',
				dataIndex: 'feedUrl',
				width: '20%',
				render: (text: string, record: any) => {
					const matchDate = moment(record.date);
					const matchNotStarted = moment().isBefore(matchDate);
					console.log(record);
					return <b className={`score ${matchNotStarted ? 'scheduled' : 'started'}`}>
						{record.postponed ? t('general.postponed') : matchNotStarted ? moment(record.date).format('DD/MM HH:mm') : `${record.homeScore}-${record.awayScore}`}
					</b>;
				}
			}, {
				title: 'Away',
				key: 'awayId',
				dataIndex: 'awayId',
				width: '40%',
				render: (awayId: any, record: any) => {
					const clubBadge = `${assetsCdn}/badges/${awayId.id}.png`;
					return <React.Fragment>
						<ClubBadgeBg src={clubBadge} />
						<ClubName className="team-name" fullName={record.awayId.name} shortName={record.awayId.short}></ClubName>
					</React.Fragment>;
				}
			},
		];

		const tableEventHandler = (match: any) => ({
			onClick: (event: any) => {
				const matchDate = moment(match.date);
				if (moment().isAfter(matchDate)) {
					this.setState({ navigateToMatchId: match.id });
				}
			},
		});

		return (
			<ContainerStyle>
				{navigateToMatchId && <Redirect
					to={{ pathname: `/match/${navigateToMatchId}` }}
				/>}
				{
					(showMonthsFilter &&
						<SelectGroupStyle>
							{(<Select
								block
								keyProperty={'id'}
								textProperty={'name'}
								onSelect={(value: any) => this.onFilterChange('month', value)}
								values={months}
								placeholder={months[0].name}
							/>) || null}
						</SelectGroupStyle>
					) || null
				}


				<FiltersArea>
					{/* {
						!filtersActivated ?
							<Button
								type="primary"
								onClick={this.enableFilters}
								size="default">
								Filter
								<Icon type="right" />
							</Button>
							: null
					} */}

					{
						filtersActivated && selectedWeekId && selectedWeekId.id ?
							<Select
								block
								keyProperty={'id'}
								textProperty={'name'}
								onSelect={(value: any) => this.onCalendarWeekChanged(value)}
								value={selectedWeekId.id}
								values={matchWeekIds}
							/>
							: null
					}
				</FiltersArea>


				{
					Object.keys(groupByDays).map((date: string, key: number) => {
						return <div key={`game-day-${key + 1}`} className={`game-day day-${key + 1}`}>
							<Title type={TitleType.h2}>{moment(date).format('dddd DD MMMM')}</Title>
							<TableStyle
								columns={columns}
								dataSource={groupByDays[date]}
								showHeader={showHeader}
								locale={{ emptyText: 'No matches found.' }}
								onRow={tableEventHandler}
								loading={isLoading}
								pagination={matchesList.length > size ? { pageSize: size } : false}
								rowKey={(record: any, index: number) => `record-${index + 1}`}
								rowClassName={(record: object, index: number) =>
									index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'
								}
							/>
						</div>
					})
				}
			</ContainerStyle>
		);
	}
}

export default Calendar;
