import styled from "styled-components";
import {Tabs} from 'antd';

import 'antd/lib/tabs/style';

export const TabsStyle = styled(Tabs)`

padding: 10px;
	&.ant-tabs {
		.ant-tabs-nav {
			width: 100%;
			
			.ant-tabs-tab {
				padding-left: 10px;
				padding-right: 10px;
				color: #939393;
			}
		}
	}
`;