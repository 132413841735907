import React, { Component } from 'react';
import { Icon } from 'antd';

import { PlayerModalStyle, PointsOverViewTable } from './PlayerModalStyle';
import { Player, Club } from './../../types';
import { theme } from "../../styles/theme-goudenspelen";
import { getPlayerPositionHexColor } from './../../lib/helpers';
import { Link } from '../UI/Link/Link';
import { ModalProps } from "antd/lib/modal";
import { PlayerBg, PlayerStyle, ClubBadgeBg, SoccerJersey } from './../Player/PlayerStyle';
import { Row, Col, PlayerType } from './../../components';
import { DeleteButtonSvg, CaptainButtonSvg, SwapButtonSvg, RollBackSvg } from './../../styles/custom-icons';
import { firstLetterUppercased } from './../../lib/helpers';

const ExitIcon = (props: any) => <Icon component={DeleteButtonSvg} {...props} />;
const CaptainIcon = (props: any) => <Icon component={CaptainButtonSvg} {...props} />;
const DeleteIcon = (props: any) => <Icon component={DeleteButtonSvg} {...props} />;
const SwapIcon = (props: any) => <Icon component={SwapButtonSvg} {...props} />;
const UndoIcon = (props: any) => <Icon component={RollBackSvg} {...props} />;

const PlayerActionsPoints: any = {
	yellowCard: { 1: -1, 2: -1, 3: -1, 4: -1 },
	redCard: { 1: -3, 2: -3, 3: -3, 4: -3 },
	time: { 1: 1, 2: 1, 3: 0, 4: 0},
	// ownGoal: { 1: -20, 2: -20, 3: -20, 4: -20 },
	matchWon: { 1: 3, 2: 3, 3: 3, 4: 3 },
	drawMatch: { 1: 1, 2: 1, 3: 1, 4: 1 },
	assist: { 1: 2, 2: 2, 3: 2, 4: 2 },
	goal: { 1: 5, 2: 4, 3: 3, 4: 3 },
	// missedPenalty: { 1: -20, 2: -20, 3: -20, 4: -20 },
	// stoppedPenalty: { 1: 10, 2: 10, 3: 10, 4: 10 },
	cleanSheet: { 1: 2, 2: 2, 3: 0, 4: 0 },
	goalTaken: { 1: -1, 2: -1, 3: 0, 4: 0 },

};


export interface PlayerModalState {
}

export interface PlayerModalProps {
	visible: boolean;
	player: Player;
	club: Club;
	positionId?: number;
	shirtSoccer?: string;
	portraitFace?: string;
	portraitFaceFallBack?: string;
	clubBadge?: string;
	onCancel: any;
	onCaptainSelect?: any;
	onRemove?: any;
	isSwapAble?: any;
	onSwap?: any;
	type?: PlayerType;
	shirtFallback?: string;
	swapPlayerId?: number | null;
	soccerJersey?: string;
}

class PlayerModal extends Component<PlayerModalProps, PlayerModalState> {

	onRemove = (event: any) => {
		this.props.onRemove(this.props.player);
		this.props.onCancel(event);
	};

	onCaptainSelect = (event: any) => {
		this.props.onCaptainSelect(this.props.player);
		this.props.onCancel(event);
	}

	onSwap = (event: any) => {
		this.props.onSwap(this.props.player);
		this.props.onCancel(event);
	}

	getPointsOverviewList = (player: any) => {
		const pointsOverview: any = [];

		Object.keys(PlayerActionsPoints)
			.map((actionName: string) => {
				switch (actionName) {
					case 'yellowCard': {
						const yellowCards = player.pointsOverview.yellow_cards || [];
						const redCards = player.pointsOverview.red_card || [];
						redCards.length <= 0 ? yellowCards.length > 0 ? pointsOverview.push({ action: 'Gele kaart', quantity: yellowCards.length, points: yellowCards.length * PlayerActionsPoints[actionName][player.positionId] }) : null : null;
						break;
					};

					case 'redCard': {
						const redCards = player.pointsOverview.red_card || [];
						redCards.length > 0 ? pointsOverview.push({ action: 'Rode kaart', quantity: redCards.length, points: redCards.length * PlayerActionsPoints[actionName][player.positionId] }) : null;
						break;
					};

					case 'time': {
						const time = player.pointsOverview.time || [];
						const played = (time >= 45)? 1 : 0;
						player.positionId < 3 ? time ? pointsOverview.push({ action: '45 min gespeeld', quantity: played, points: played * PlayerActionsPoints[actionName][player.positionId] }) : null : null;
						break;
					};

					// case 'ownGoal': {
					// 	const ownGoals = player.pointsOverview.own_goals || [];
					// 	pointsOverview.push({ action: 'Eigen doelpunt', quantity: ownGoals.length, points: ownGoals.length * PlayerActionsPoints[actionName][player.positionId] });
					// 	break;
					// };

					case 'matchWon': {
						const wonMatches = player.pointsOverview.wonMatches || (player.pointsOverview.time >= 60 && player.pointsOverview.result === 'W' ? 1 : 0) || 0;
						wonMatches ? pointsOverview.push({ action: 'Overwinning', quantity: wonMatches, points: wonMatches * PlayerActionsPoints[actionName][player.positionId] }) : null;
						break;
					};

					case 'drawMatch': {
						const drawMatches = player.pointsOverview.drawMatches || (player.pointsOverview.time >= 60 && player.pointsOverview.result === 'D' ? 1 : 0) || 0;
						drawMatches ? pointsOverview.push({ action: 'Gelijkspel', quantity: drawMatches, points: drawMatches * PlayerActionsPoints[actionName][player.positionId] }) : null;
						break;
					};

					case 'assist': {
						const assists = player.pointsOverview.assists || [];
						assists.length > 0 ? pointsOverview.push({ action: 'Assist', quantity: assists.length, points: assists.length * PlayerActionsPoints[actionName][player.positionId] }) : null;
						break;
					};

					case 'goal': {
						const goals = player.pointsOverview.goals || [];
						goals.length > 0 ? pointsOverview.push({ action: 'Doelpunt', quantity: goals.length, points: goals.length * PlayerActionsPoints[actionName][player.positionId] }) : null;
						break;
					};

					// case 'missedPenalty': {
					// 	const penaltyMissed = player.pointsOverview.penalty_miss || [];
					// 	pointsOverview.push({ action: 'Gemiste penalty', quantity: penaltyMissed.length, points: penaltyMissed.length * PlayerActionsPoints[actionName][player.positionId] });
					// 	break;
					// };

					// case 'stoppedPenalty': {
					// 	const stoppedPenalty = player.pointsOverview.penalty_saved || [];
					// 	pointsOverview.push({ action: 'Gestopte penalty', quantity: stoppedPenalty.length, points: stoppedPenalty.length * PlayerActionsPoints[actionName][player.positionId] });
					// 	break;
					// };

					case 'cleanSheet': {
						const cleanSheet = player.pointsOverview.conceeded ?
							(player.pointsOverview.time >= 60 && player.pointsOverview.conceeded.length === 0 ? 1 : 0) : 0
							player.positionId < 3 ? cleanSheet ? pointsOverview.push({ action: 'Clean sheet', quantity: cleanSheet, points: cleanSheet * PlayerActionsPoints[actionName][player.positionId] }) : null : null;
						break;
					};

					case 'goalTaken': {
						const goalTaken = player.pointsOverview.conceeded || [];
						player.positionId < 3 ? goalTaken ? goalTaken.length > 0 ? pointsOverview.push({ action: 'Per tegendoelpunten', quantity: goalTaken.length, points: goalTaken.length * PlayerActionsPoints[actionName][player.positionId] }) : null : null : null;
						break;
					};
				}
			});

		return pointsOverview;
	};

	render() {
		const { player, shirtSoccer, club, onCaptainSelect, onRemove, onSwap, swapPlayerId, shirtFallback, isSwapAble, type, portraitFace,
			portraitFaceFallBack, clubBadge, soccerJersey } = this.props;

		const PositionLabels: any = {
			1: 'Doelman',
			2: 'Verdediger',
			3: 'Middenvelder',
			4: 'Aanvaller'
		};
		const playerName = (player && `${player.short}`);

		const playerPositionColor = getPlayerPositionHexColor(player, theme);

		const showPointsOverview = player && player.pointsOverview && player.points !== null && player.points !== undefined;
		let pointsOverview: any = [];

		if (showPointsOverview) {
			pointsOverview = this.getPointsOverviewList(player);
		}

		const title = <span>{playerName}</span>;

		let totalActions = 0;

		if (onCaptainSelect) {
			totalActions += 1;
		}

		if (onSwap && (player.id !== swapPlayerId)) {
			totalActions += 1;
		}

		if (onSwap && (player.id === swapPlayerId)) {
			totalActions += 1;
		}

		if (onRemove) {
			totalActions += 1;
		}

		const actionColumnSize = 24 / totalActions;

		return (
			<PlayerModalStyle
				title={title}
				visible={this.props.visible}
				onCancel={this.props.onCancel}
				footer={[]}
			>
				<Row className="player-header">
					<Col md={8} sm={8} xs={8}>
						<PlayerStyle className="player-avatar">
							{PlayerType.SoccerShirt === type ? <SoccerJersey src={shirtSoccer} fallback={shirtFallback} /> : null}
							{PlayerType.SoccerPortrait === type ? <PlayerBg src={portraitFace} /> : null}
							{PlayerType.SoccerPortrait === type ? <ClubBadgeBg src={clubBadge} />: null}
							{PlayerType.SoccerPortrait === type ? <SoccerJersey bg={soccerJersey} /> : null}
						</PlayerStyle>
					</Col>
					<Col md={16} sm={16} xs={16}>
						<p className={'surname'}>{player.short}</p>
						<p className={'club'}>{club.name}</p>
						<p className={'position'} style={{ color: playerPositionColor }}>{PositionLabels[player.positionId]}</p>

						{
							showPointsOverview ?
								<span className={'points'}>
									<span className={'value'}>{player.points}</span>
									<span className={'label'}>PUNTEN</span>
								</span> :
								null
						}
					</Col>
				</Row>
				<Row>
					{
						onCaptainSelect ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onCaptainSelect}>
									<CaptainIcon />
									Aanvoerder
								</div>
							</Col> :
							null
					}

					{
						onSwap && isSwapAble && isSwapAble(player) && (player.id !== swapPlayerId) ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onSwap}>
									<SwapIcon />
									Wissel
								</div>
							</Col> :
							null
					}

					{
						onSwap && (player.id === swapPlayerId) ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onSwap}>
									<UndoIcon />
									Maak ongedaan
								</div>
							</Col> :
							null
					}

					{
						onRemove && !swapPlayerId ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onRemove}>
									<DeleteIcon />
									Verwijder
								</div>
							</Col> :
							null
					}
				</Row>
				{
					showPointsOverview ?
						<PointsOverViewTable>
							<thead>
								<tr>
									<th>ACTIE</th>
									<th>AANTAL</th>
									<th>PUNTEN</th>
								</tr>
							</thead>
							<tbody>
								{
									pointsOverview.map((category: any, index: number) =>
										<tr key={`overview-${index}`}>
											<td>{category.action}</td>
											<td>{category.quantity}</td>
											<td>{category.points}</td>
										</tr>
									)
								}
							</tbody>
						</PointsOverViewTable> : null
				}
			</PlayerModalStyle>
		)
	}
}


export default PlayerModal;