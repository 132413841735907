import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Icon } from 'antd';
import { Route } from 'react-router-dom';
import {
	Layout,
	Row,
	Col,
	Block
} from '../../components';
import {
	Team,
	Points,
	EditTeam,
	Leagues,
	Transfers
} from './../';

import { ApplicationState, UserState, MatchesState } from '../../types';
import { FootballTeamSvg, FootballShoeSvg } from './../../styles/custom-icons';


import { TeamHeaderStyle, TeamContainerStyles } from './TeamContainerStyles';

const PointsIcon = (props: any) => <Icon component={FootballShoeSvg} {...props} />;
const TeamIcon = (props: any) => <Icon component={FootballTeamSvg} {...props} />;
const TransferIcon = (props: any) => <Icon component={TransferSvg} {...props} />;
const LeagueIcon = (props: any) => <Icon component={LeagueSvg} {...props} />;

export interface Props {
	match: any;
	matches: MatchesState;
	application: ApplicationState;
	user: UserState;
	t: any;
}

export interface State {
}

class TeamContainer extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {

		}
	};

	render() {
		const { match, user, matches, application } = this.props;

		const teamId = match.params.id;
		const team = user && user.teams && user.teams.find((team: any, index: number) => team.id === parseInt(teamId));
		const isActive = (match: string) => location.pathname === match;
		const gameInProgress = !!matches.info.deadlineWeek;
		const showPoints = !gameInProgress || (team && matches.info.deadlineWeek && (matches.info.deadlineWeek > team.weekId));
		const gameOfficiallyStarted = matches.info.deadlineWeek && matches.info.deadlineWeek > this.props.application.competition.officialStartWeek;
		const showTeamEdit = team && matches.info.deadlineWeek && (matches.info.deadlineWeek === team.weekId);
		const showEdit = team && team.id && matches && matches.info && matches.info.deadlineWeek && (matches.info.deadlineWeek > team.weekId) && (matches.info.deadlineWeek > application.competition.competitionStartingWeek);		
		
		// console.log(team);
		// console.log("IN PROGRESS");
		// console.log(gameInProgress);
		// console.log("Started");
		// console.log(gameOfficiallyStarted);
		// console.log("SHOW POINTS");
		// console.log(showPoints);
		// console.log("EDIT")
		// console.log(showEdit);
		return (
			<Layout>
			<TeamContainerStyles>
				<Row>
					<Col md={24}>
						{
							team ? 
							<TeamHeaderStyle>
								<ul>
									{
										showPoints ?
										<li className={`${isActive(`${match.url}/points`) ? 'active' : ''}`}>
											<Link to={`${match.url}/points`}> <PointsIcon /> Punten</Link>
										</li> :
										null
									}
									{
										gameInProgress ?
										<li className={`${isActive(`${match.url}`) ? 'active' : ''}`}>
											<Link to={`${match.url}`}> <TeamIcon /> Team</Link>
										</li> : 
										null
									}
									{
										gameInProgress && gameOfficiallyStarted && !showTeamEdit ?
											<li className={`${isActive(`${match.url}/transfers`) ? 'active' : ''}`}>
												<Link to={`${match.url}/transfers`}> <TransferIcon /> Transfers</Link>
											</li> :
											null
									}
									<li className={`${isActive(`${match.url}/league`) ? 'active' : ''}`} >
										<Link to={`${match.url}/league`}><LeagueIcon /> Superklasse</Link>
									</li>
								</ul>
							</TeamHeaderStyle>
							: null
						}
					</Col>
				</Row>

				<Row>
					<Col md={24}>
						<Route
							exact path={`${match.path}`}
							render={(props) =>
								gameInProgress ? 
									(showPoints ? 
										(gameOfficiallyStarted ? <Team {...props} t={this.props.t} /> 
										: <EditTeam {...props} t={this.props.t} />)
									: <EditTeam {...props} t={this.props.t} />) 
								: <Points {...props} t={this.props.t} />
							}
						/>
						<Route
							path={`${match.path}/points`}
							render={(props) => (
								<Points {...props} t={this.props.t} />
							)}
						/>
						<Route path={`${match.path}/league`} component={Leagues} />
						<Route path={`${match.path}/transfers`} component={Transfers} />
					</Col>
				</Row>
			</TeamContainerStyles>
			</Layout>
		);
	}
}

function PointsSvg() {
	return <svg width="16px" height="14px" viewBox="0 0 16 14" version="1.1">
		<title>Icon / Menu / Points</title>
		<desc>Created with Sketch.</desc>
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Icon-/-Menu-/-Points" transform="translate(0.000000, -1.000000)" fill="#000">
				<g id="download-(5)" transform="translate(0.000000, 1.600000)">
					<path d="M15.6923077,0.209836066 L0.307692308,0.209836066 C0.194403128,0.209836066 0.102564103,0.303782872 0.102564103,0.419672131 L0.102564103,12.5901639 C0.102564103,12.7060532 0.194403128,12.8 0.307692308,12.8 L1.12820513,12.8 C1.24149431,12.8 1.33333333,12.7060532 1.33333333,12.5901639 L1.33333333,4.84511475 L14.6748718,4.84511475 L14.6748718,12.5901639 C14.6748718,12.7060532 14.7667108,12.8 14.88,12.8 L15.7005128,12.8 C15.813802,12.8 15.905641,12.7060532 15.905641,12.5901639 L15.905641,0.419672131 C15.9056857,0.362534891 15.8829514,0.30784975 15.842689,0.268247418 C15.8024267,0.228645086 15.7481183,0.207550557 15.6923077,0.209836066 Z M0.914871795,12.3803279 L0.504615385,12.3803279 L0.504615385,0.629508197 L0.914871795,0.629508197 L0.914871795,12.3803279 Z M13.5076923,4.42544262 L13.5076923,3.27763934 L12.1374359,3.27763934 L12.1374359,4.42544262 L10.7651282,4.42544262 L10.7651282,3.27763934 L9.39282051,3.27763934 L9.39282051,4.42544262 L8.0225641,4.42544262 L8.0225641,3.27763934 L6.65025641,3.27763934 L6.65025641,4.42544262 L5.28,4.42544262 L5.28,3.27763934 L3.90769231,3.27763934 L3.90769231,4.42544262 L2.53538462,4.42544262 L2.53538462,3.27763934 L1.32512821,3.27763934 L1.32512821,1.87383607 L2.53538462,1.87383607 L2.53538462,0.629508197 L3.90769231,0.629508197 L3.90769231,1.86754098 L5.28,1.86754098 L5.28,0.629508197 L6.65230769,0.629508197 L6.65230769,1.86754098 L8.02461538,1.86754098 L8.02461538,0.629508197 L9.39692308,0.629508197 L9.39692308,1.86754098 L10.7692308,1.86754098 L10.7692308,0.629508197 L12.1415385,0.629508197 L12.1415385,1.86754098 L13.5138462,1.86754098 L13.5138462,0.629508197 L14.6810256,0.629508197 L14.6810256,1.86754098 L13.5076923,1.86754098 L13.5076923,3.27134426 L14.6748718,3.27134426 L14.6748718,4.41914754 L13.5076923,4.42544262 Z M15.4871795,12.3803279 L15.0769231,12.3803279 L15.0769231,0.629508197 L15.4871795,0.629508197 L15.4871795,12.3803279 Z" id="Shape" fillRule="nonzero"></path>
					<rect id="Rectangle" fillRule="nonzero" x="2.53538462" y="1.87383607" width="1.37230769" height="1.40380328"></rect>
					<rect id="Rectangle" fillRule="nonzero" x="5.28" y="1.87383607" width="1.37230769" height="1.40380328"></rect>
					<rect id="Rectangle" fillRule="nonzero" x="8.0225641" y="1.87383607" width="1.37230769" height="1.40380328"></rect>
					<rect id="Rectangle" fillRule="nonzero" x="10.7651282" y="1.87383607" width="1.37230769" height="1.40380328"></rect>
				</g>
			</g>
		</g>
	</svg>;
};

function LeagueSvg() {
	return <svg width="13px" height="14px" viewBox="0 0 13 14" version="1.1">
		<title>Icon / Menu / Trophy</title>
		<desc>Created with Sketch.</desc>
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Icon-/-Menu-/-Trophy" transform="translate(-1.000000, -1.000000)" fill="#000">
				<g id="download-(3)" transform="translate(1.600000, 1.600000)">
					<path d="M11.7602133,1.00892444 L10.1511111,1.00892444 L10.1511111,0.373617778 C10.1512533,0.105244444 10.0458667,-1.77635684e-15 9.77763556,-1.77635684e-15 L2.40369778,-1.77635684e-15 C2.13546667,-1.77635684e-15 2.03008,0.105244444 2.03008,0.373617778 L2.03008,1.00892444 L0.470897778,1.00892444 C0.213333333,1.00892444 0.112213333,1.11004444 0.112213333,1.36760889 L0.112213333,1.39733333 C0.194702222,3.47875556 1.56970667,5.24188444 3.65269333,5.98741333 C4.10183111,6.65116444 4.65123556,7.23484444 5.3184,7.71399111 C5.2352,8.96824889 4.79260444,9.84220444 4.09287111,10.6574222 C4.03911111,10.7198578 4.03655111,10.76992 4.14392889,10.76992 L8.03768889,10.76992 C8.14492444,10.76992 8.14236444,10.72 8.08860444,10.6574222 C7.38872889,9.84220444 6.94613333,8.96853333 6.86293333,7.71399111 C7.52270222,7.24039111 8.06712889,6.66410667 8.51342222,6.00974222 C10.6325333,5.27786667 12.0354133,3.50023111 12.1188978,1.39733333 L12.1188978,1.36760889 C12.11904,1.11004444 12.0177778,1.00892444 11.7602133,1.00892444 Z M0.903253333,1.83011556 C0.896995556,1.78929778 0.908373333,1.75616 0.967395556,1.75616 L2.11171556,1.75616 C2.28750222,2.87004444 2.57948444,3.92462222 3.02151111,4.86997333 C1.86979556,4.18787556 1.09667556,3.09546667 0.903253333,1.83011556 Z M11.3277156,1.83011556 C11.1304533,3.12007111 10.3307378,4.23011556 9.14190222,4.90922667 C9.59416889,3.95406222 9.89155556,2.88554667 10.0694756,1.75630222 L11.2635733,1.75630222 C11.3227378,1.75630222 11.3341156,1.78944 11.3277156,1.83011556 Z" id="Shape" fillRule="nonzero"></path>
					<path d="M9.08003556,11.1433956 L3.10129778,11.1433956 C2.85354667,11.1433956 2.72768,11.2696889 2.72768,11.5170133 L2.72768,12.4263822 C2.72768,12.6739911 2.85368889,12.8 3.10129778,12.8 L9.08003556,12.8 C9.32778667,12.8 9.45365333,12.6739911 9.45365333,12.4263822 L9.45365333,11.5170133 C9.45365333,11.2695467 9.32764444,11.1433956 9.08003556,11.1433956 Z" id="Path" fillRule="nonzero"></path>
				</g>
			</g>
		</g>
	</svg>
}

function TransferSvg() {
	return <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1">
		<title>Icon / Menu / Transfer</title>
		<desc>Created with Sketch.</desc>
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
			<g id="Icon-/-Menu-/-Transfer" transform="translate(1.000000, -1.000000)" stroke="#000">
				<g id="download-(4)" transform="translate(0.000000, 1.600000)">
					<polyline id="Path" strokeWidth="1.39318885" points="8 10.5106767 11.7814821 10.5106579 11.7814821 12.5659802 15.9998512 8.45533553 11.7814821 4.34470024 11.7814821 6.40001318 4.21834476 6.39999435 4.21834476 8.45531671 -2.4247619e-05 4.34467953 4.21834476 0.234029176 4.21834476 2.28935341 8 2.28931765"></polyline>
				</g>
			</g>
		</g>
	</svg>
}

function TeamSvg() {
	return <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1">
		<title>Icon / Menu / Team</title>
		<desc>Created with Sketch.</desc>
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Icon-/-Menu-/-Team" transform="translate(0.000000, -2.000000)" fill="#000">
				<g id="download-(6)" transform="translate(0.000000, 2.700000)">
					<path d="M9.63411761,11.5354855 C9.92535618,11.5728252 10.1307458,11.8358377 10.0928682,12.1229403 C10.0549906,12.4100429 9.78818923,12.6125157 9.49695069,12.5751761 L0.489189653,11.4202911 C0.0667978133,11.3661362 -0.122807755,10.8688497 0.158826427,10.5538335 L5.83777573,4.20176036 C6.03196206,3.98455718 6.36799571,3.96366279 6.5883279,4.15509148 C6.80866006,4.34652014 6.82985542,4.67778171 6.63566909,4.89498489 L1.61772522,10.5077039 L9.63411761,11.5354855 Z" id="Path" fillRule="nonzero"></path>
					<path d="M6.25043875,3.12493475 L9.68448891,11.0216554 L15.8152494,3.09265426 L15.5387554,2.52070381 L6.25043875,3.12493475 Z M5.80607934,2.1031145 L15.8286233,1.45112003 C16.0459223,1.4369841 16.2500032,1.55488526 16.3436894,1.74868278 L16.9149823,2.9304491 C17.0007165,3.10779733 16.9786663,3.31751488 16.8578572,3.47375896 L9.97779474,12.3718433 C9.73093717,12.6911078 9.22656275,12.6295686 9.0661128,12.2606083 L4.07534644,0.784163283 C3.95962765,0.51806376 4.08464283,0.209871057 4.35457572,0.0957955773 C4.62450861,-0.018279867 4.93714106,0.10495998 5.05285988,0.371059504 L5.80607934,2.1031145 Z" id="Shape" fillRule="nonzero"></path>
					<path d="M14.9015613,0.811239176 C14.7720766,0.551376338 14.8808037,0.237238452 15.14441,0.109592577 C15.4080163,-0.0180532977 15.7266796,0.0891296927 15.8561644,0.348992531 L17.9318879,4.51476103 C18.0613726,4.77462387 17.9526456,5.08876176 17.6890392,5.21640763 C17.4254329,5.34405351 17.1067696,5.23687051 16.9772849,4.97700768 L14.9015613,0.811239176 Z" id="Path" fillRule="nonzero"></path>
				</g>
			</g>
		</g>
	</svg>;
}

export {
	TeamIcon,
	PointsIcon
};

export default TeamContainer;